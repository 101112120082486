import React from "react"
import { motion } from "framer-motion"

import ImageMobile from "../../static/about-image-mobile.jpg"
import ImageDesktop from "../../static/about-image.jpg"
import { containerAnimation } from "../shared/utils/motionAnimations"
import aboutData from "./about-data"

import styles from "./about.module.scss"

export default function About() {
  return (
    <motion.div
      className={styles.container}
      variants={containerAnimation}
      initial="initial"
      animate="animate"
    >
      <div className={styles.textContent}>
        <div className={styles.aboutItem}>
          <span className={styles.icon} role="img" aria-hidden="true">
            👩🏻‍💻
          </span>
          <p>I am a self-taught UX/UI designer living in London.</p>
        </div>
        <div className={styles.aboutItem}>
          <span className={styles.icon} role="img" aria-hidden="true">
            ♥️
          </span>
          <p>
            What I love about this field is how you get to be part of people's
            lives, learning about their emotions, motivations and the best way
            to solve their problems along the creation process.
          </p>
        </div>
        <div className={styles.aboutItem}>
          <span className={styles.icon} role="img" aria-hidden="true">
            📚
          </span>
          <p>
            I started my career as a designer studying at the{" "}
            <a
              href="https://www.interaction-design.org/mayse-falsiroli-de-oliveira"
              target="_blank"
              rel="noreferrer noopener"
            >
              Interaction Design Foundation
            </a>{" "}
            where I was able to explore some fields such as the user-centred
            design process, usability, psychology and business market.
          </p>
        </div>
        <div className={styles.aboutItem}>
          <span className={styles.icon} role="img" aria-hidden="true">
            👠
          </span>
          <p>I worked as a fashion model for twelve years.</p>
        </div>
        <div className={styles.aboutItem}>
          <span className={styles.icon} role="img" aria-hidden="true">
            🎮
          </span>
          <p>
            When I’m not studying or working on my projects, I like to watch
            anime, all kinds of tv shows, movies, play video games and spend
            some time with my husband.
          </p>
        </div>
        <div className={styles.aboutItem}>
          <span className={styles.icon} role="img" aria-hidden="true">
            🐶
          </span>
          <p>I have four little doggies: Hulk, Lola, Thor & Pipinha.</p>
        </div>
        <div className={styles.aboutItem}>
          <span className={styles.icon} role="img" aria-hidden="true">
            👋🏻
          </span>
          <p>
            If you think I’m a match for your team or wanna send me a feedback
            about my work or just wanna say hi and talk about design, drop me an
            email at{" "}
            <a
              href="mailto:falsirolimayse@gmail.com"
              title="falsirolimayse@gmail.com"
            >
              falsirolimayse@gmail.com
            </a>
            , I’m looking forward to hearing from you.
          </p>
        </div>
      </div>
      <div
        className={styles.imageContainerMobile}
        style={{ backgroundImage: `url('${ImageMobile}')` }}
      />
      <div
        className={styles.imageContainer}
        style={{ backgroundImage: `url('${ImageDesktop}')` }}
      />
    </motion.div>
  )
}
