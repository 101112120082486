import React from "react"

import Layout from "../shared/components/Layout/Layout"
import Seo from "../Seo/Seo"
import AboutPage from "../AboutPage/AboutPage"

export default function About({ path }) {
  return (
    <Layout>
      <Seo
        title="Mayse Falsiroli - About me"
        keywords={[
          `ux`,
          `ui`,
          `design`,
          `redesign`,
          `mayse falsiroli`,
          `case study`,
        ]}
      />
      <AboutPage />
    </Layout>
  )
}
